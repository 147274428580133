import { Link } from "react-router-dom";
import "../styles/home.css";

export default function Home() {
  return (
    <main className="landing-page">
      <h1 className="site-title">Fredie AI</h1>
      <p>
      "Fredie AI menyediakan alat AI inovatif secara gratis, termasuk generator gambar dan chatbot canggih, untuk membantu Anda mengeksplorasi kreativitas dan kecerdasan buatan dengan mudah."
      </p>
      <h2>Coba Sekarang</h2>
      <div className="ai-menus">
        <Link to="/chat">Chat AI</Link>
        <Link to="/image">Image Generator</Link>
      </div>
    </main>
  );
}
